<template>
  <v-app id="recordings">
    <v-container class="my-12">
      <v-row>
        <v-col cols="12" md="10" offset-md="1" class="text-right">
          <v-btn @click="signOut()">Çıkış</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <h1 class="mb-4">Canlı Ders Kayıtları</h1>
          <v-card>
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              loading-text="Yükleniyor..."
              item-key="course"
              show-expand
            >
              <template v-slot:[`item.url`]="{ item }">
                <a :href="item.url" target="_blank">Kaydı İzle</a>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <h3 class="mb-4">Katılımcı Listesi</h3>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Ad-Soyad
                          </th>
                          <th class="text-left">
                            KGK Sicil
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="subItem in item.participants"
                          :key="subItem.name"
                        >
                          <td>{{ subItem.name }}</td>
                          <td>{{ subItem.id }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        { text: "Eğitim Adı", value: "course", align: "start",
          filterable: true },
        { text: "Canlı Ders Kodu", value: "recording" },
        { text: "Zaman", value: "datetime" },
        { text: "Kayıt", value: "url" }
      ],
      items: [
        {
          course: "Finansal Tabloların Dönüşümünde Özellikli Konular",
          recording: "2020-09-11/2.Egitim",
          datetime: "09 Kasım 2020, 14:00",
          url: "https://vk.finman.com.tr/playback/presentation/2.0/playback.html?meetingId=1d1ee060eeeb617615a60678c40b1f967e79efe3-1604907599477",
          participants: [
            {
              name: "AHMET HAYVALI",
              id: "BD/2013/02267"
            },
            {
              name: "ZAFER  KUTLU",
              id: "BD/2013/02031"
            },
            {
              name: "OSMAN İŞNAS",
              id: "BD/2013/04679"
            },
            {
              name: "SEBAHATTİN MEMU",
              id: "BD/2013/02873"
            },
            {
              name: "MEHMET KÖSE",
              id: "BD/2013/02822"
            },
            {
              name: "FİKRET  KOÇ",
              id: "BD/2013/02673"
            },
            {
              name: "HATİCE ALTUNGEYİK",
              id: "BD/2013/01614"
            },
            {
              name: "L. HAYAT FELAH",
              id: "BD/2013/01618"
            },
            {
              name: "İSKENDER DOĞRU",
              id: "BD/2015/12446"
            },
            {
              name: "RACİ  CÜZDAN",
              id: "BD/2013/00745"
            },
            {
              name: "SÜLEYMAN SEMİZ",
              id: "BD/2013/03278"
            },
            {
              name: "GÜNAY MORKAN",
              id: "BD/2017/15236"
            },
            {
              name: "AHMET OKUR",
              id: "BD/2015/15684"
            },
            {
              name: "MEHMET AKARSU",
              id: "BD/2014/05322"
            },
            {
              name: "MEHMET YOLCU",
              id: "BD/2013/00201"
            },
            {
              name: "ALİ ÖZAY",
              id: "BD/2015/11738"
            },
            {
              name: "COŞKUN AKTAŞ",
              id: "BD/2015/11459"
            },
            {
              name: "MUZAFFER ERÇELİK",
              id: "BD/2013/00347"
            },
            {
              name: "YAŞAR KOÇ",
              id: "BD/2013/00350"
            },
            {
              name: "NECDET SAĞLAM",
              id: "BD/2014/06216"
            },
            {
              name: "ENDERCAN TURUŞLAR",
              id: "BD/2013/02454"
            },
            {
              name: "ÖZGÜR KARAMİL",
              id: "BD/2018/16255"
            },
            {
              name: "YÜCEL ÜNVER",
              id: "BD/2013/03958"
            },
            {
              name: "ALİ KARAMİL",
              id: "BD/2014/06261"
            }
          ]
        }
      ]
    };
  },
  mounted() {
    if (!this.$store.state.isSignedIn) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    signOut() {
      this.$store.commit("signOut");
      this.$router.push({ name: "Home" });
    }
  }
};
</script>

<style lang="scss">
#recordings {
  .v-data-table tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    & > td {
      padding: 20px;
    } 
  }
}
</style>
